.pooling__dropdown {
    position: relative;
    display: inline-block;
    font-family: ClearSans;
    line-height: normal;
    font-size: 1rem;
    display: inline-block;
    min-width: 11em;
    cursor: pointer;
}

.pooling__dropdown > .field {
    display: flex;
    min-height: 33px;
}

.pooling__dropdown input {
    border: 0px solid transparent;
    box-shadow: none;
    outline: none;
    width: 100%;
}

.pooling__dropdown input::-ms-clear {
    display: none;
}

.pooling__dropdown div.text {
    width: 100%;
}

.pooling__dropdown > .field,
.pooling__dropdown > .menu > .item {
    padding: 0.5rem 0.9rem;
}

.pooling__dropdown > .field div.text {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: pointer;
}

.pooling__dropdown > .field div.text {
    padding-right: 1em;
}

.pooling__dropdown > .field div.text.default,
.pooling__dropdown input::placeholder {
    color: #c4c4c4;
}

.pooling__dropdown > .menu > .item {
    cursor: pointer;
}
.pooling__dropdown > .menu > .item.not-found {
    color: #c4c4c4;
    cursor: default;
}

.pooling__dropdown > .menu > .item:not(.not-found):hover {
    background-color: #f0f5f8;
}

.pooling__dropdown,
.pooling__dropdown > .menu {
    -webkit-appearance: none;
    -moz-appearance: none;
    margin: 0;
    max-width: 100%;
    text-align: left;
    background: #fff;
    border: 1px solid #696969;
    color: #202020;
    border-radius: 25px;
    -webkit-box-shadow: none;
    box-shadow: none;
    outline: none;
}

.pooling__dropdown:focus,
.pooling__dropdown > .field div.text:hover,
.pooling__dropdown.open,
.pooling__dropdown.open > .menu {
    border-color: #135ca9;
}

.pooling__dropdown.error,
.pooling__dropdown.error > .menu,
.pooling__dropdown.multiple.error .tag {
    border-color: #990202;
}

.pooling__dropdown.disabled,
.pooling__dropdown > div.text {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border-color: #c4c4c4;
    color: #c4c4c4;
}

.pooling__dropdown.fluid,
.pooling__dropdown.fluid > .field,
.pooling__dropdown.fluid div.text {
    width: 100%;
}

.pooling__dropdown > .menu {
    margin-top: 8px;
    display: none;
    overflow-x: hidden;
    overflow-y: auto;
    max-height: 200px;
    position: absolute;
    background-color: #ffffff;
    width: 100%;
    z-index: 3;
    -ms-overflow-style: none;
}

.pooling__dropdown.open > .menu {
    display: block;
}

.pooling__dropdown > .field > i.dropdown-icon {
    display: inline-flex;
    cursor: pointer;
    align-items: center;
}

.pooling__dropdown > .field > i.dropdown-icon path {
    fill: #475566;
}

.pooling__dropdown.open > .field > i.dropdown-icon path {
    fill: #135ca9;
}

.pooling__dropdown.error > .field > i.dropdown-icon path {
    fill: #990202;
}

/* Multiple selection */

.pooling__dropdown.multiple {
    min-height: 40px;
}

.pooling__dropdown.multiple > .field {
    padding: 2px 16px 2px 8px;
}

.pooling__dropdown.multiple.empty > .field {
    padding: 9.5px 16px;
}

.pooling__dropdown.multiple .tags {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}

.pooling__dropdown.multiple .tag {
    padding: 3px 11px 4px;
    border: 1px solid #696969;
    border-radius: 20px;
    margin: 3px 0;
}

.pooling__dropdown.multiple .tag:hover {
    border-color: #135ca9;
}

.pooling__dropdown.multiple .tag:not(:last-child) {
    margin-right: 8px;
}

.pooling__dropdown.multiple .tag > span {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    white-space: nowrap;
}

.pooling__dropdown.multiple .tag > i {
    display: inline-flex;
    cursor: pointer;
    align-items: center;
    margin-left: 10px;
    height: 100%;
}

.pooling__dropdown.multiple .tag > i:hover path {
    fill: #135ca9;
}

.pooling__dropdown.multiple input {
    width: auto;
}

/* Scrollbar part */

.pooling__dropdown > .menu::-webkit-scrollbar {
    width: 14px !important;
}

.pooling__dropdown > .menu::-webkit-scrollbar-thumb {
    cursor: pointer;
    border-right: 5px solid transparent !important;
    border-left: 5px solid transparent !important;
    background-color: #135ca9 !important;
    background-clip: padding-box !important;
}

.pooling__dropdown.error > .menu::-webkit-scrollbar-thumb {
    background-color: #990202 !important;
}

.pooling__dropdown > .menu::-webkit-scrollbar-track {
    background-color: transparent !important;
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    margin-bottom: 26px;
    margin-top: 26px;
}
