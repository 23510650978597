/* Main CSS file which shold be separated */

* {
    padding: 0;
    margin: 0;
    text-decoration: none;
    list-style-type: none;
}

html, body, #root{
    width: 100%;
    height: 100%;
}
body {
    color: #202020;
    min-width: 1280px;
}
@media (max-width: 1280px) {
    body {
        overflow-x: inherit;
    }
}
.container{
    padding: 10px 15px 0;
}
.container.big{
    padding: 20px 40px;
}
.container.medium{
    padding: 0 10%;
}
.container.no-top{
    padding-top: 0;
}

table{
    width: 100%;
}

p{
    margin-bottom: 0.5rem;
}

.row {
    display: flex;
    flex-wrap: wrap;
}


.col-1 {
    flex: 0 0 5.5555555556%;
    max-width: 5.5555555556%; }
  
  .col-2 {
    flex: 0 0 11.1111111111%;
    max-width: 11.1111111111%; }
  
  .col-3 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%; }
  
  .col-4 {
    flex: 0 0 22.2222222222%;
    max-width: 22.2222222222%; }
  
  .col-5 {
    flex: 0 0 27.7777777778%;
    max-width: 27.7777777778%; }
  
  .col-6 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%; }
  
  .col-7 {
    flex: 0 0 38.8888888889%;
    max-width: 38.8888888889%; }
  
  .col-8 {
    flex: 0 0 44.4444444444%;
    max-width: 44.4444444444%; }
  
  .col-9 {
    flex: 0 0 50%;
    max-width: 50%;
    }
  
  .col-10 {
    flex: 0 0 55.5555555556%;
    max-width: 55.5555555556%; }
  
  .col-11 {
    flex: 0 0 61.1111111111%;
    max-width: 61.1111111111%; }
  
  .col-12 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%; }
  
  .col-13 {
    flex: 0 0 72.2222222222%;
    max-width: 72.2222222222%; }
  
  .col-14 {
    flex: 0 0 77.7777777778%;
    max-width: 77.7777777778%; }
  
  .col-15 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%; }
  
  .col-16 {
    flex: 0 0 88.8888888889%;
    max-width: 88.8888888889%; }
  
  .col-17 {
    flex: 0 0 94.4444444444%;
    max-width: 94.4444444444%; }
  
  .col-18 {
    flex: 0 0 100%;
    max-width: 100%; }
  

h3, .h3,
h4, .h4,
.reservation span,
.days-week .col-xl,
.reservation span.home,
.jq-selectbox__select,
.btn-header .btn.btn-primary,
.chart .title-chart,
.navig b,
.datepick-calendare,
.legend,
.reservation-btn div > span,
.info .type {
    font-family: "ClearSans", Arial, sans-serif;
}

body, .calendar, .calendar span{
    font-family: "ClearSans", Arial, sans-serif;
}

label {
    display: inline-block;
    vertical-align: top;
    margin-bottom: 0.5rem;
}

h3, .h3 {
    font-size: 1.1rem;
    display: block;
    margin-bottom: 0;
}

h4, .h4 {
    font-size: 0.9rem;
    display: inline-block;
    margin-right: 15px;
}

.filter-col h4{
    margin: 10px 0px 5px;
}

.reservation {
    padding: 15px 10px 15px !important;
    transition: ease-in 0.05s;
    overflow: hidden;
}

.reservation-inner{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.info{
    text-align: left;
    flex-grow: 1;
    min-height: 152px;
}

.reservation:hover {
    -webkit-box-shadow: 0px 0px 18px -4px rgba(0,0,0,0.2) !important;
    -moz-box-shadow: 0px 0px 18px -4px rgba(0,0,0,0.2) !important;
    box-shadow:  0px 0px 18px -4px rgba(0,0,0,0.2) !important;
}

.dc-info{
    display: flex;
    align-items: top;
    justify-content: left;
    height: 100%;
}
.dc-info h5{
    margin: 20px 0 20px;
}

.dc-info .logo-company, .reservation .logo-company {
    margin: 0 auto;
    display: block;
    height: 50px;
    width: 130px;
    background-color: white;
    padding: 0;
    border-radius: 4px;
    background-size: contain !important;
}

.dc-info .logo-company{
    height: 50px;
    max-width: 130px;
    margin: 0;
}

.reservation .info span {
    display: inline-block;
    margin: 2px 4px 3px 44px;
    position: relative;
}

.reservation .home {
    color: #5299EE;
    border-bottom: 2px dotted transparent;
}

    .reservation .home a {
        color: #5299EE;
        border-bottom: 2px dotted #5299EE;
    }

.booked .btn.btn-primary {
    color: #fff;
}

.reservation .price {
    text-align: center;
    margin-bottom: 15px;
}

    .reservation .price b {
        font-size: 1.5rem;
    }

.reservation .ico {
    width: 21px;
    height: 20px;
    position: absolute;
    display: block;
    left: -37px;
}

    .reservation .ico.icon-home {
        background: url("/img/icons/home.svg") no-repeat;
    }

    .reservation .ico.icon-date {
        background: url("/img/icons/date.svg") no-repeat;
    }

    .reservation .ico.icon-amount {
        background: url("/img/icons/pallet.svg") no-repeat;
    }

    .reservation .ico.icon-truck {
        background: url("/img/icons/truck.svg") no-repeat;
        background-size: contain;
    }

.reservation-btn{
    min-height: 40px;
}

.logo-box{
    margin-bottom: 8px;
}

.info-inner-block{
    font-weight: normal !important;
    min-height: 2rem;
}

.sticker {
    display: block;
    position: absolute;
    background: url("/img/sticker.svg") no-repeat;
    height: 100px;
    width: 100px;
    background-size: contain;
    right: 0;
    top: 0;
}

.greencheck {
    display: inline-block;
    width: 16px;
    height: 16px;
    background: url("/img/greencheck.png") no-repeat;
    margin-left: 6px;
}

.text-align-center {
    text-align: center;
}

.text-align-left {
    text-align: left;
}

.inline-block {
    display: inline-block;
}

.days-week .col-xl {
    color: #94A2B2;
    font-size: 1rem;
    line-height: 4;
    text-align: center;
    padding: 10px;
    min-width: 227px;
}

.sticker-none, .greencheck-none {
    display: none;
}

.price .disabled-book {
    color: #AEBED2;
}

.booked {
    background: #5299EE !important;
    color: white;
}

.booked.reservation .home {
    color: #fff;
}

.booked.reservation .home a {
    color: #fff;
    border-bottom: 2px dotted #fff;
}

.booked.reservation .ico.icon-home {
    background: url("/img/icons/home-white.svg") no-repeat;
}

.booked.reservation .ico.icon-date {
    background: url("/img/icons/date-white.svg") no-repeat;
}

.booked.reservation .ico.icon-amount {
    background: url("/img/icons/pallet-white.svg") no-repeat;
}

.booked.reservation .ico.icon-truck {
    background: url("/img/icons/truck-white.svg") no-repeat;
}


.fixed-header{
    position: fixed;
    top:-15px;
    left:0;
    width: 100% !important;
    min-width: 1280px !important;
    background: white;
    z-index: 10;
    display: none !important;
}

.shown{
    display: block !important;
}


/* Всплывающая подсказка*/
.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
    border-top-color: #fff;
}

.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
    border-right-color: #fff;
}

.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
    border-bottom-color: #fff;
}

.bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
    border-left-color: #fff;
}

.tooltip-inner {
    max-width: 250px;
    color: #000;
    background-color: #fff;
    border-radius: 3px;
    box-shadow: 0 2px 10px rgba(1, 97, 199, 0.2);
    z-index: 9999;
}

.this-day {
    background: #f0f5f8;
}

.is-prev-day {
    opacity: 0.6;
}

/* Конец - Всплывающая подсказка*/


canvas {
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    position: relative;
    max-width: 1400px;
    margin: auto;
}

.chart {
    min-width: 1240px;
}

    .chart .title-chart {
        padding: 60px 20px 20px;
        font-size: 1rem;
    }

.chart .title-chart .left-chart {
}

.chart .title-chart .center-chart {
    text-align: center;
    font-size: 20px;
}

.chart .title-chart .right-chart {
    text-align: right;
    padding: 0;
}

.navig a {
    text-decoration: none;
}

.navig b {
    font-size: 1rem;
    color: #475566;
    padding: 0 0 0 60px;
    position: relative;
    top: 4px
}

.chart .table {
    margin: 50px 0;
    padding-top: 50px;
}

.table th, .table td {
    padding: 1rem;
    vertical-align: inherit;
    text-align: left;
    border-top: 1px solid #dee2e6;
}

.table tr:last-child {
    border-bottom: 1px solid #dee2e6;
}

.table.results th, .table.results td {
    text-align: center;
}
.table.results tr th:first-child {
    text-align: left;
}

#chartjs-tooltip {
    background: white;
    border: 1px solid;
}

.chart-body .jq-selectbox__select {
    width: 200px;
}

.chart-body .type .jq-selectbox__select {
    width: 150px;
}

.chart-body .btn-header {
    text-align: right;
}

.table .red {
    font-size: 26px;
    color: red;
}

.party .navig {
    margin-left: 70px;
}

.legend-body {
    margin: 50px 0;
    display: flex;
    justify-content: center;
}

.chartjs-legend {
    display: flex;
}

.legend {
    position: relative;
    width: 250px;
    margin: 0 55px;
    font-size: 1rem;
    text-align: right;
    cursor: pointer;
    color: #475566;
    -moz-user-select: none;
    -khtml-user-select: none;
    user-select: none;
}

    .legend:before {
        content: "";
        border-radius: 20px;
        background: linear-gradient(to top, #5BC1FA, #2E8EF4);
        width: 100px;
        height: 5px;
        position: absolute;
        left: 0;
        top: 10px;
    }

    .legend.chart-legend2:before {
        background: linear-gradient(to top, #FDC700, #FF9700);
    }

    .legend.chart-legend0:before {
        background: url("/img/dash.png") no-repeat;
    }

    .legend.chart-legend0 {
        order: 3;
    }

    .legend.chart-legend1 {
        order: 1;
    }

    .legend.chart-legend2 {
        order: 2;
    }

.logo {
    background: url("/img/logo.svg");
}

.loginForm {
    padding-left: 20px;
    padding-top: 20px;
}

    .loginForm .row {
        margin-bottom: 15px;
    }

    .loginForm .col-sm-3 {
        text-align: center;
        padding-top: 4px;
    }

a {
    cursor: pointer;
}


.profile-modal {
    max-width: 553px;
}

    .profile-modal .tab-pane {
        height: 251px;
    }

.map-container {
    height: 315px;
    margin-bottom: 15px;
}

    .map-container > .label {
        text-align: center;
        padding-top: 150px;
    }


.warehouses {
    max-height: 500px;

    overflow-x: hidden;
    overflow-y: scroll;
}

button.add-warehouse-btn {
    width: 100%;
    margin: 20px 0px !important;
}

.add-warehouse-form {
    padding: 10px;
    margin-top: 10px;
    margin-bottom: 30px;
    border: 1px solid #dee2e6;
    border-radius: 5px;
}


    .add-warehouse-form .name input {
        padding: 0 0 0 8px;
    }

    .add-warehouse-form .address {
        padding-top: 5px;
    }

        .add-warehouse-form .address input {
            padding: 0 0 0 8px;
        }

    .add-warehouse-form .action button {
        padding: 1px 15px;
    }

.reservation-modal textarea {
    resize: none;
    margin-top: 15px;
}

.reservation-modal .get-from-client-form {
    border: 1px solid #dee2e6;
    padding: 10px;
    margin: 15px 0px;
    border-radius: 5px;
}

.react-datepicker__time-list {
    padding: 0 !important;
}

.select-distribution-center {
    width: 120px;
    padding: 3px;
    border-radius: 5px;
}

.row.warehouse {
    padding: 10px;
    margin: 5px;
    background-color: #f5f5f5
}

.loginForm .error {
    color: red;
}

.loginForm .thanks {
    color: #000000;
}


.help-message {
    color: coral;
    margin-left: 10px;
}

.form-check {
    padding-left: 0px !important;
}

.actions-header-container {
    padding-top: 22px;
}


.general-report .tab-content {
    height: 415px;
    overflow-y: scroll;
}

.general-report .total-row {
    background-color: beige;
}

.general-report .greencheck {
    position: relative;
    right: auto;
    display: inline-block;
}

.only-not-null-checkbox {
    position: absolute;
    top: 33px;
    left: 52px;
}

.padding-0 {
    padding: 0;
}

.permission{
    border: 1px solid #dddddd;
    padding: 3px;
    margin-bottom: 3px;
    border-radius: 3px;
}

.report-table-wrapper {
    overflow-x: scroll;
    margin: 0 40px;
}

.report-table {
    min-width: 1392px;
    margin-top: 5px;
}

.reservation-number {
    margin-bottom: 10px;
    display: inline-block;
}

div.ReactTags__tags {
    position: relative;
}

/* Styles for the input */
div.ReactTags__tagInput {
    width: 200px;
    border-radius: 2px;
    display: inline-block;
}

    div.ReactTags__tagInput input.ReactTags__tagInputField,
    div.ReactTags__tagInput input.ReactTags__tagInputField:focus {
        height: 31px;
        margin: 0;
        font-size: 12px;
        width: 100%;
        border: 1px solid #eee;
        padding: 0 4px;
    }

/* Styles for selected tags */
div.ReactTags__selected span.ReactTags__tag {
    color: #fffaf2;
    border: 1px solid #f0f5f8;
    background: #3a9af5;
    font-size: 12px;
    display: inline-block;
    padding: 5px;
    margin: 0 5px;
    cursor: move;
    border-radius: 5px;
}

div.ReactTags__selected a.ReactTags__remove {
    color: #fff;
    margin-left: 5px;
    cursor: pointer;
}

/* Styles for suggestions */
div.ReactTags__suggestions {
    position: absolute;
    z-index: 9999;
}

    div.ReactTags__suggestions ul {
        list-style-type: none;
        box-shadow: .05em .01em .5em rgba(0,0,0,.2);
        background: white;
        width: 200px;
    }

    div.ReactTags__suggestions li {
        border-bottom: 1px solid #ddd;
        padding: 5px 10px;
        margin: 0;
    }

        div.ReactTags__suggestions li mark {
            text-decoration: underline;
            background: none;
            font-weight: 600;
        }

    div.ReactTags__suggestions ul li.ReactTags__activeSuggestion {
        background: #b7cfe0;
        cursor: pointer;
    }

.rc-filter-label {
    padding-left: 72px;
    padding-top: 4px;
}

.profil-name a.dropdown-item {
    color: inherit;
}

.create-tariff {
    position: absolute;
    right: 15px;
    padding: 5px !important;
}

.slot-in-carousel {
    width: 100%;
    margin: 0 !important;
    cursor: pointer;
}

.active-slot {
    text-decoration: underline;
}

.subscribe-btn {
    position: fixed;
    bottom: 300px;
    /* right: 100px; */
    left: -77px;
    /* top: 32px; */
    -webkit-transform: rotate(-270deg);
    -ms-transform: rotate(-270deg);
    border-radius: 10px 10px 0px 0px;
    color: white;
    background-color: rgb(0, 123, 255);
    z-index: 999;
}

.subscribe-form input {
    font-family: 'ClearSans';
    line-height: normal;
    font-size: 1rem;
    padding: 0.55rem 1.2rem 0.65rem;
    background: #fff;
    border: 1px solid #135CA9;
    border-radius: 1.35rem;
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    font-size: 0.9rem;
    width: 190px;
    margin-right: 10px;
    outline: none;
}

.subscribe-form button {
    background-color: #FFFFFF;
    border-radius: 1.35rem;
    border: 1px solid #135CA9;
    box-sizing: border-box;
    color: #000000;
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    cursor: pointer;
    padding: 0.55rem 1.2rem 0.65rem;
    font-size: 0.9rem;
    outline: none;
}

    .subscribe-form button:hover {
        background-color: #135CA9;
        color: #FFFFFF;
    }

.subscribe-form .msg-alert{
    display: inline-block;
    margin-left: 10px;
}
.subscribe-form .msg-alert p{
    color: black !important;
}

.warning {
    color: coral;
}

.error {
    color: red;
}

.jq-selectbox__select {
    padding-left: 17px;
    text-align: center;
}

.users {
    margin-top: 10px;
}

.login-modal {
    max-width: 758px !important;
}

.tariff-map-wrapper {
    display: inline;
}

.tariffs-need-login-page {
    text-align: center;
}

.w-10 {
    width: 10% !important;
}

.w-12 {
    width: 12% !important;
}

.w-15 {
     width: 15% !important;
 }

.w-20 {
     width: 20% !important;
 }

.tariff-date {
    line-height: 36px;
    font-size: 1rem;
    width: 130px;
    font-family: "ClearSans", Arial, sans-serif;
}

.prolongation-date {
    line-height: 24px; 
    width: 110px;
}

.date-field {
    width: 110px !important;
    text-align: center;
}

.wrapper-scroll-x {
    overflow-x: scroll;
}

.carrier-report {
    padding-top: 20px;
}

.open-sub-menu  .sub-menu {display: block}

.add-button-tab-item {
    padding: 0 !important;
}
.add-button-tab {
    padding: 1.28285714em !important;
    border-radius: 0 !important;
    width: 100% !important;
    background: transparent !important;
    border: none !important;
    color: #555 !important;
}

/***************report*******************/

.ui.grid.report .row:not(.filter) {
    overflow-x: auto;
}

.ui.grid.report .row.filter > .column:first-child,
.ui.grid.report .row.filter > .column:last-child {
    display: inline-flex;
    -ms-align-items: center;
    -o-align-items: center;
    -webkit-align-items: center;
    align-items: center;
    -o-justify-content: center;
    -webkit-justify-content: center;
    justify-content: center;
}

.ui.grid.report .row.filter > .column:not(:first-child):not(:last-child) .form .fields {
    -o-justify-content: center;
    -webkit-justify-content: center;
    justify-content: center;
}

.ui.grid.report .row.filter > .column:not(:first-child):not(:last-child) .form .fields:last-child {
    margin-bottom: 0;
}


/***************datePicker*******************/

.react-datepicker-wrapper {
    display: block !important;
}
.react-datepicker__input-container {
    width: 100% !important;
}
.react-datepicker-popper {
    z-index: 5 !important;
}

/***************Toastify*******************/

.Toastify__toast{
    border-radius: 1.35rem !important;
    background-color: #135CA9 !important;
    color: white !important;
}

.Toastify__toast--info{
    background-color: #135CA9 !important;
    color: white !important;
}

.Toastify__toast--error {
    background-color: #990202 !important;
    color: white !important;
}

.Toastify__toast--warning {
    background-color: #766400 !important;
    color: white !important;
}

.Toastify__close-button{
    color: white !important;
    opacity: 0.3 !important;
    padding: 0 5px !important;
}