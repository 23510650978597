#region-selection {
    display: block;
    width: 350px;
    margin-bottom: 30px;
}

.pooling__segment {
    margin: 0 -20px;
    padding: 20px;
    background-color: #FFFFFF;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
}

.mailing-grid-wrapper {
    display: -ms-grid;
    display: grid;

    -ms-grid-columns: 1fr 3em 1fr 3em 1fr 3em 1fr 3em 1fr 3em 1fr 3em 1fr 3em 1fr;
    -ms-grid-rows: 1fr 2em 1fr 2em 1fr 2em 1fr;

    grid-column-gap: 3em;
    grid-row-gap: 2em;
    grid-template-columns: repeat(8, 1fr);
    grid-template-areas: 
        "cr cr cr ct pt pt pt dl"
        "cl cl cl dy dy dy dy dl"
        "dc dc dc dc dc dc dc dl"
        "tg tg tg tg tg tg tg dl";

    align-items: end;
}

@media screen and (max-width: 1810px) {
    .mailing-grid-wrapper {
        grid-template-areas: 
            "cr cr cr ct ct pt pt dl"
            "cl cl cl dy dy dy dy dl"
            "dc dc dc dc dc dc dc dl"
            "tg tg tg tg tg tg tg dl";
    }
}

label > svg {
    margin-left: 10px;
}

.carrier {
    -ms-grid-column: 1;
    -ms-grid-column-span: 5;
    -ms-grid-row: 1;

    grid-area: cr;
}

.client {
    -ms-grid-column: 1;
    -ms-grid-column-span: 5;
    -ms-grid-row: 3;

    grid-area: cl;
}

.car-type {
    -ms-grid-column: 7;
    -ms-grid-column-span: 9;
    -ms-grid-row: 1;
    -ms-grid-row-align: end;

    grid-area: ct;
}
    .car-type > label {
        display: block;
    }

.product-type {
    -ms-grid-column: 11;
    -ms-grid-row: 1;

    grid-area: pt;
}

    .product-type > label {
        display: block;
    }

.days {
    -ms-grid-column: 7;
    -ms-grid-column-span: 7;
    -ms-grid-row: 3;
    -ms-grid-row-align: end;

    grid-area: dy;
}
    .days > label {
        display: block;
    }

.distribution {
    -ms-grid-column: 1;
    -ms-grid-column-span: 13;
    -ms-grid-row: 5;

    grid-area: dc;
}

.toggle {
    -ms-grid-column: 1;
    -ms-grid-column-span: 13;
    -ms-grid-row: 7;

    grid-area: tg;
}

.delete {
    -ms-grid-column: 15;
    -ms-grid-row: 1;
    -ms-grid-row-span: 7;
    -ms-grid-column-align: end;
    -ms-grid-row-align: start;

    grid-area: dl;
    align-self: start;
    justify-self: end;

    cursor: pointer;
}

.car-type > div,
.days > div {
    display: flex;
    margin: 7px 0;
}