.white-container {
    height: 100%;
    padding: 40px 10px 20px;
    font-family: ClearSans;
    font-size: 1rem;
}

    .white-container p {
        line-height: normal;
        color: #000000;
        margin-bottom: 0.6em;
    }

    .white-container label {
        line-height: normal;
        color: #000000;
    }

p.head-text {
    font-size: 1.75rem;
    font-weight: bold;
    color: #135CA9;
    margin-bottom: 1em;
}

p.subhead-text {
    font-size: 22px;
    color: #135CA9;
    margin-bottom: 0.6667em;
}

p.blue-text,
label.blue-text,
span.blue-text {
    color: #135CA9;
    font-weight: bold;
}

.pooling__user-tab {
    padding: 0 13px;
}

label.error {
    color: #990202;
}

    label.error.description {
        white-space: nowrap;
        font-size: 12px;
        margin: 0;
    }