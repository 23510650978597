.pooling__borderless-table {
    font-family: ClearSans;
    line-height: normal;
    width: 100%;
    color: #000000;
}

.pooling__borderless-table .left-aligned {
    text-align: left;
}

.pooling__borderless-table .center-aligned {
    text-align: center;
}

.pooling__borderless-table .right-aligned {
    text-align: right;
}

    .pooling__borderless-table > thead  {
        font-size: 1rem;
        text-align: center;
    }

        .pooling__borderless-table > thead th {
            padding: 13px;
        }

    .pooling__borderless-table > tbody {
        text-align: center;
    }

    .pooling__borderless-table > tbody td {
        padding: 20px 0;
    }

    .pooling__borderless-table tr.top-bordered {
        border-top: 1px solid #F0F5F8;
    }

    .pooling__borderless-table tr.bottom-bordered {
        border-bottom: 1px solid #F0F5F8;
    }

.dot-icon {
    padding: 0 6px;
}

    .dot-icon > div {
        height: 6px;
        width: 6px;
        background: #135CA9;
        border-radius: 3px;
    }

.modal-headtext {
    padding-top: 0;
    padding-bottom: 20px;

    text-align: center;

    font-size: 20px;
    color: #000000;
}

.modal-buttons {
    display: inline-flex;
    width: 100%;
    justify-content: center;
}

.modal-buttons > div:not(:last-child) {
    margin-right: 15px;
}