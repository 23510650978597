.pooling__checkbox {
    font-family: ClearSans;
    line-height: normal;
    font-size: 1rem;
    display: flex;
    align-items: center;
    height: 30px;
    color: #000000;
}

    .pooling__checkbox:not(:last-child) {
        margin-right: 20px;
    }

.pooling__checkbox>.check {
    width: 21px;
    height: 21px;
    background: #FFFFFF;
    border: 1px solid #696969;
    box-sizing: border-box;
    border-radius: 6px;
    cursor: pointer;
    display: flex;
    user-select: none;
}

.pooling__checkbox.disabled>.check {
    border-color: #C4C4C4;
}

    .pooling__checkbox:not(.disabled)>.check:hover {
        border-color: #135CA9;
    }

.pooling__checkbox>.check>i {
    padding: 6px 5px;
    display: inline-flex;
}

    .pooling__checkbox>.check>i path {
        fill: transparent;
    }

    .pooling__checkbox.checked>.check>i path {
        fill: #475566;
    }

        .pooling__checkbox.checked:not(.disabled)>.check>i:hover path {
            fill: #135CA9;
        }

        .pooling__checkbox.checked.disabled>.check>i path {
            fill: #C4C4C4;
        }

.pooling__checkbox>label {
    margin: 0;
    padding: 0 0 1px 6px;
    cursor: pointer;
}

.pooling__checkbox.error>.check,
.pooling__checkbox.error>.check:hover {
    border-color: #990202;
}

    .pooling__checkbox.error.checked>.check>i path {
        fill: #990202;
    }