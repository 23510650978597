header {
    margin: 0;
}

.calendar-filter {
    background: #F0F5F8;
}


    .ui.grid.results.filter-row {
        min-width: 1360px;
    }

        .ui.grid.results.filter-row .week-selector {
            min-width: 250px;
        }

    .ui.grid.filter-row {
        min-width: 1200px;
    }

        .ui.grid.filter-row > .row {
            margin-left: 0;
            margin-right: 0;
        }

            .ui.grid.filter-row > .row:not(:last-child) {
                padding-bottom: 0;
            }

            .ui.grid.filter-row > .row:not(:first-child) {
                padding-top: 0;
            }

            .ui.grid.filter-row > .row > .column.centered-height {
                top: 37%;
                z-index: 3;
            }

.column.select-date {
    -ms-align-items: center;
    -o-align-items: center;
    -webkit-align-items: center;
    align-items: center;
    padding-top: 2px;
}

    .column.select-date > .row {
        display: flex;
        -o-justify-content: center;
        -webkit-justify-content: center;
        justify-content: center;
        -ms-align-items: center;
        -o-align-items: center;
        -webkit-align-items: center;
        align-items: center;
    }

        .column.select-date > .row > .column > a > p, .ruleReservationBtn {
            font-size: 0.9rem;
            color: #135CA9;
            line-height: 10px;
            display: inline-block;
            border-bottom: 2px dotted #135CA9;
            padding-top: 5px;
            padding-bottom: 5px;
        }
        .column.select-date > .row > .column > a.current-week > p {
            color: #bbb;
            border-bottom: 2px dotted #bbb;
        }


            .ruleReservationBtn:hover {
                text-decoration: none;
                color: #135CA9;
            }

        .column.select-date > .row > .column:not(:first-child):not(:last-child) {
            padding: 0 15px;
            text-align: center;
        }


            .column.select-date > .row > .column > button:hover {
                color: white;
                background-color: #135CA9;
            }

.column.filter-col {
    padding: 0 20px;
    min-width: 225px;
}

.ui.buttons.ingrid {
    display: flex;
    -o-justify-content: center;
    -webkit-justify-content: center;
    justify-content: center;
    -ms-align-items: center;
    -o-align-items: center;
    -webkit-align-items: center;
    align-items: center;
    padding: 5px 50px;
}

    .ui.buttons.ingrid > button.active:first-child {
        background: #135CA9;
        color: white;
    }

    .ui.buttons.ingrid > button.active:not(:first-child):not(:last-child) {
        background-color: #135CA9;
        color: white;
    }

    .ui.buttons.ingrid > button.active:last-child {
        background: #135CA9;
        color: white;
    }

    .ui.buttons.ingrid > button:not(.active) {
        background-color: transparent;
        background: no-repeat;
        color:#135CA9;
    }

.ui.buttons.circular > button {
    border: 1px #135CA9 solid !important;
}

    .ui.buttons.circular > button:first-child {
        border-radius: 0;
        -ms-border-top-left-radius: 1.4rem !important;
        border-top-left-radius: 1.4rem !important;
        -ms-border-bottom-left-radius: 1.4rem !important;
        border-bottom-left-radius: 1.4rem !important;
        border-left-style: solid;
        border-color: #135CA9;
        border-right-width: 0;
    }

    .ui.buttons.circular > button:last-child {
        border-radius: 0;
        -ms-border-top-right-radius: 1.4rem;
        border-top-right-radius: 1.4rem;
        -ms-border-bottom-right-radius: 1.4rem;
        border-bottom-right-radius: 1.4rem;
        border-right-style: solid;
        border-color: #135CA9;
        border-left-width: 0;
    }

.filter-checkbox > label {
    font-family: "ClearSans", Arial, sans-serif;
    text-align: center;
    display: block;
    color: rgba(71, 85, 102, 1.0);
}

.select-date .ui.button:focus{
    background: white;
    color: #555;
}
