.date-manage-picker {
    height: 220px;
    -o-justify-content: center;
    -webkit-justify-content: center;
    justify-content: center;
    text-align: center;
}

.date-manage-picker .datepicker-container,
.date-manage-picker .ui.button {
    margin: 0 auto;
}