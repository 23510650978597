.pooling__radio {
    font-family: ClearSans;
    line-height: normal;
    font-size: 16x;
    display: flex;
    align-items: center;
    height: 30px;
    color: #000000;
}

    .pooling__radio:not(:last-child) {
        margin-right: 20px;
    }

.pooling__radio>.check {
    width: 21px;
    height: 21px;
    background: #FFFFFF;
    border: 2px solid #696969;
    box-sizing: border-box;
    border-radius: 12.5px;
    cursor: pointer;
    display: flex;
    user-select: none;
}

    .pooling__radio>.check:hover {
        border-color: #135CA9;
    }

.pooling__radio>.check>i {
    padding: 3px;
    display: inline-flex;
}

    .pooling__radio>.check>i circle {
        fill: transparent;
    }

    .pooling__radio.checked>.check>i circle {
        fill: #475566;
    }

        .pooling__radio.checked>.check>i:hover circle {
            fill: #135CA9;
        }

.pooling__radio>label {
    margin: 0;
    padding: 0 0 3px 6px;
    cursor: pointer;
}

.pooling__radio.error>.check,
.pooling__radio.error>.check:hover {
    border-color: #990202;
}

    .pooling__radio.error.checked>.check>i circle {
        fill: #990202;
    }