.calendar > .ui.celled.grid {
    margin-top: 0;
}

.magnit-service {
    justify-content: center;
    align-items: center;
}

.magnit-service img {
    width: 100%;
    max-width: 142px;
    margin-right: 15px;
}